import * as React from "react";
import { Component } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import accordionContentList from "./copyList";
import downloadjs from "downloadjs";

class Accordions extends Component {
  constructor() {
    super();
  }
  handleDownload = (fileName) => {
    downloadjs(fileName);
  };

  downloadImages = (imageUrls) => {
    const triggerDownload = (url, fileName) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    imageUrls.forEach((imageUrl, index) =>
      triggerDownload(imageUrl, `image${index + 1}.jpg`)
    );
  };

  render() {
    const ContextAwareToggle = ({ children, eventKey, callback }) => {
      const currentEventKey = useContext(AccordionContext);
      const isCurrentEventKey = currentEventKey === eventKey;
      let trackLabel = "";
      switch (eventKey) {
        case "0":
          trackLabel = "National HIV Testing Day";
          break;
        case "1":
          trackLabel = "Southern HIV/AIDS Awareness Day";
          break;
        case "2":
          trackLabel = "National Gay Men's HIV/AIDS Awareness Day";
          break;
        case "3":
          trackLabel = "National Latino AIDS Awareness Day";
          break;
        case "4":
          trackLabel = "World AIDS Day";
          break;
        case "5":
          trackLabel = "NATIONAL BLACK HIV/AIDS AWARENESS DAY";
          break;
        case "6":
          trackLabel = "National Women and Girls HIV/AIDS Awareness Day";
          break;
      }
      const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
      );
      return (
        <button
          type="button"
          className={`${isCurrentEventKey ? "active" : ""}`}
          onClick={decoratedOnClick}
          /* data-analytics-event-category="Accordions/Expanders"
          data-analytics-event-label={`${trackLabel} ${
            isCurrentEventKey ? "Open" : "Closed"
          }`} */
        >
          {children}
        </button>
      );
    };

    return (
      <>
        <Accordion className="body" defaultActiveKey="0">
          {accordionContentList.map((item, i) => {
            if (
              item.date.getTime() >= item.todayDate.getTime() &&
              item.isActive == true &&
              item.isEmpty == false
            ) {
              return (
                <Card>
                  <Card.Header>
                    <ContextAwareToggle
                      as={Button}
                      variant="link"
                      eventKey={item.eventKey}
                      callback={() => this.props.scrollToSection(item.scrollTo)}
                    >
                      <h2
                        data-anchor={item.scrollTo}
                        className="title"
                        dangerouslySetInnerHTML={{ __html: item.header }}
                      ></h2>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={item.eventKey}>
                      <Card.Body>
                        <Container className="pl-0">
                          <Row>
                            <Col xs={12} lg={12} className="pb-4">
                              <Row>
                                <Col xs={12} lg={item.imageCount === 3 ? 4 : 6 } className="pb-4">
                                  <p className="title">
                                    <strong>{item.titleLeft}</strong>
                                  </p>
                                  <img
                                    className={item.imageCount === 3 ? 'd-none d-md-block img-fluid' : 'd-none d-md-block custom-mw-375' }
                                    src={item.imageLeft}
                                    alt={item.imageAltLeft}
                                  />
                                  <img
                                    className="d-block d-md-none w-100"
                                    src={item.imageMobileLeft}
                                    alt={item.imageAltLeft}
                                  />
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkLeft)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 3 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkLeft)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p className="download-caption">
                                      <strong>{item.downloadCaptionLeft}</strong>
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-caption-text"
                                      dangerouslySetInnerHTML={{
                                        __html: item.downloadCaptionTextLeft,
                                      }}
                                    ></p>
                                  )}
                                </Col>
                                {item.imageCount === 3 && (
                                  <Col xs={12} lg={4} className="pb-4">
                                    <p className="title">
                                      <strong>{item.titleMiddle}</strong>
                                    </p>
                                    <img
                                      className="d-none d-md-block img-fluid"
                                      src={item.imageMiddle}
                                      alt={item.imageAltMiddle}
                                    />
                                    <img
                                      className="d-block d-md-none w-100"
                                      src={item.imageMobileMiddle}
                                      alt={item.imageAltMiddle}
                                    />
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkMiddle)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  </Col>
                                )}
                                <Col xs={12} lg={item.imageCount === 3 ? 4 : 6 } className="pb-4">
                                  <p className="title">
                                    <strong>{item.titleRight}</strong>
                                  </p>
                                  <img
                                    className={item.imageCount === 3 ? 'd-none d-md-block img-fluid' : 'd-none d-md-block custom-mw-375' }
                                    src={item.imageRight}
                                    alt={item.imageAltRight}
                                  />
                                  <img
                                    className="d-block d-md-none w-100"
                                    src={item.imageMobileRight}
                                    alt={item.imageAltRight}
                                  />
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkRight)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 3 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkRight)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p className="download-caption">
                                      <strong>{item.downloadCaptionRight}</strong>
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-caption-text"
                                      dangerouslySetInnerHTML={{
                                        __html: item.downloadCaptionTextRight,
                                      }}
                                    ></p>
                                  )}
                                </Col>
                                {item.imageCount === 3 && (
                                  <Col xs={12} lg={12} className="pb-4">
                                  <p className="download-caption">
                                    <strong>{item.downloadCaptionMiddle}</strong>
                                  </p>
                                  <p
                                    className="download-caption-text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.downloadCaptionTextMiddle,
                                    }}
                                  ></p>
                                </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Accordion.Collapse>
                </Card>
              );
            } else if (
              item.date.getTime() >= item.todayDate.getTime() &&
              item.isActive &&
              item.isEmpty == true
            ) {
              return (
                <Card>
                  <Card.Header>
                    <ContextAwareToggle
                      as={Button}
                      variant="link"
                      eventKey={item.eventKey}
                      callback={() => this.props.scrollToSection(item.scrollTo)}
                    >
                      <h2
                        data-anchor={item.scrollTo}
                        className="title"
                        dangerouslySetInnerHTML={{ __html: item.header }}
                      ></h2>
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={item.eventKey}>
                    <Card.Body
                      dangerouslySetInnerHTML={{ __html: item.defaultContent }}
                    ></Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            }
          })}

          {accordionContentList
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((item, i) => {
              if (
                item.date.getTime() < item.todayDate.getTime() &&
                item.isActive == false &&
                item.isEmpty == false
              ) {
                return (
                  <Card>
                    <Card.Header>
                      <ContextAwareToggle
                        as={Button}
                        variant="link"
                        eventKey={item.eventKey}
                        callback={() =>
                          this.props.scrollToSection(item.scrollTo)
                        }
                      >
                        <h2
                          data-anchor={item.scrollTo}
                          className="title"
                          dangerouslySetInnerHTML={{ __html: item.header }}
                        ></h2>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={item.eventKey}>
                      <Card.Body>
                        <Container className="pl-0">
                          <Row>
                            <Col xs={12} lg={12} className="pb-4">
                              <Row>
                                <Col xs={12} lg={item.imageCount === 3 ? 4 : 6 } className="pb-4">
                                  <p className="title">
                                    <strong>{item.titleLeft}</strong>
                                  </p>
                                  <img
                                    className={item.imageCount === 3 ? 'd-none d-md-block img-fluid' : 'd-none d-md-block custom-mw-375' }
                                    src={item.imageLeft}
                                    alt={item.imageAltLeft}
                                  />
                                  <img
                                    className="d-block d-md-none w-100"
                                    src={item.imageMobileLeft}
                                    alt={item.imageAltLeft}
                                  />
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkLeft)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 3 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkLeft)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p className="download-caption">
                                      <strong>{item.downloadCaptionLeft}</strong>
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-caption-text"
                                      dangerouslySetInnerHTML={{
                                        __html: item.downloadCaptionTextLeft,
                                      }}
                                    ></p>
                                  )}
                                </Col>
                                {item.imageCount === 3 && (
                                  <Col xs={12} lg={4} className="pb-4">
                                    <p className="title">
                                      <strong>{item.titleMiddle}</strong>
                                    </p>
                                    <img
                                      className="d-none d-md-block img-fluid"
                                      src={item.imageMiddle}
                                      alt={item.imageAltMiddle}
                                    />
                                    <img
                                      className="d-block d-md-none w-100"
                                      src={item.imageMobileMiddle}
                                      alt={item.imageAltMiddle}
                                    />
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkMiddle)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  </Col>
                                )}
                                <Col xs={12} lg={item.imageCount === 3 ? 4 : 6 } className="pb-4">
                                  <p className="title">
                                    <strong>{item.titleRight}</strong>
                                  </p>
                                  <img
                                    className={item.imageCount === 3 ? 'd-none d-md-block img-fluid' : 'd-none d-md-block custom-mw-375' }
                                    src={item.imageRight}
                                    alt={item.imageAltRight}
                                  />
                                  <img
                                    className="d-block d-md-none w-100"
                                    src={item.imageMobileRight}
                                    alt={item.imageAltRight}
                                  />
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkRight)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 3 && (
                                    <p
                                      className="download-link pt-3"
                                      onClick={() =>
                                        this.handleDownload(item.downloadLinkRight)
                                      }
                                    >
                                      <strong>Download</strong>{" "}
                                      <img
                                        className="download-icon"
                                        src={"/download-icon.png"}
                                        alt=""
                                      />
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p className="download-caption">
                                      <strong>{item.downloadCaptionRight}</strong>
                                    </p>
                                  )}
                                  {item.imageCount === 2 && (
                                    <p
                                      className="download-caption-text"
                                      dangerouslySetInnerHTML={{
                                        __html: item.downloadCaptionTextRight,
                                      }}
                                    ></p>
                                  )}
                                </Col>
                                {item.imageCount === 3 && (
                                  <Col xs={12} lg={12} className="pb-4">
                                  <p className="download-caption">
                                    <strong>{item.downloadCaptionMiddle}</strong>
                                  </p>
                                  <p
                                    className="download-caption-text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.downloadCaptionTextMiddle,
                                    }}
                                  ></p>
                                </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              } else if (
                item.date.getTime() < item.todayDate.getTime() &&
                item.isActive == false &&
                item.isEmpty == true
              ) {
                return (
                  <Card>
                    <Card.Header>
                      <ContextAwareToggle
                        as={Button}
                        variant="link"
                        eventKey={item.eventKey}
                        callback={() =>
                          this.props.scrollToSection(item.scrollTo)
                        }
                      >
                        <h2
                          data-anchor={item.scrollTo}
                          className="title"
                          dangerouslySetInnerHTML={{ __html: item.header }}
                        ></h2>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={item.eventKey}>
                      <Card.Body
                        dangerouslySetInnerHTML={{
                          __html: item.defaultContent,
                        }}
                      ></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              }
            })}
        </Accordion>
      </>
    );
  }
}

export default Accordions;
